const baseUrl = "/pdt/customProduct";

/** 获取列表-参数 */
export interface PageParam {
    productId: number;
}

//定义表格的属性
export interface TableDataType {
    id: number;
    productId: number;
    fileName: string;
    appMSize: string;
    appVersion: number;
    iconPath: string;
    filePath: string;
    packageName: string;
    md5: string;
    enableStatus: string;
    notes: string;
}

export {baseUrl};