const baseUrl = "/pdt/product";
/** 获取列表-参数 */
export interface PageParam {
    productName: string;
    productTypeCd: string;
}

//定义表格的属性
export interface TableDataType {
    id: number;
    productName: string;
    productCode: string;
    productIcon: string;
    productTypeDesc: string;
    relaObject: string;
    displayModeDesc: string;
    ownerTypeDesc: string;
    ownerObjectId: string;
    statusDesc: string;
    notes: string;
    productImage: string;
    productIntroImage: string;
    cornerIcon: string;
    defaultSelected:number;
    defaultSelectedDesc:string;
    unitPrice:number;
    customProduct:number;
    customProductDesc:string
}

export  {baseUrl};