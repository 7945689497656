import {request} from "../../../utils";
import {OptionRes, PageRes} from "../../types/common";
import {PaginationProps} from "antd";
import {baseUrl, PageParam, TableDataType} from "../../types/mkt/redeemCode/redeemCodeUseVo";
import commonApi from "../../commonApi";


const redeemCodeUseApi = {

    pageData(body: PageParam,pagination: PaginationProps) {
        let url = baseUrl+"/page";
        let queryParams = new URLSearchParams();

        // 添加分页参数
        queryParams.set('current', pagination.current.toString());
        queryParams.set('size', pagination.pageSize.toString());

        for (const key in body) {
            console.log("key:" +  key + ":" + body[key])
            if (body.hasOwnProperty(key) && body[key] !== null && body[key] !== undefined && body[key] !== '') {
                let valueToEncode = body[key];

                queryParams.set(key, valueToEncode);
            }
        }

        // 拼接查询参数到 URL
        url += '?' + queryParams.toString();

        return request.get<PageRes<TableDataType>>(url);
    },

    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive"
        commonApi.derive(url,"兑换码日志列表",body)
    },



}

export default redeemCodeUseApi;



