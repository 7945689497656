import React, {useEffect, useState} from "react";
import {
    Button, ConfigProvider,
    Divider,
    Form,
    Input,
    message,
    Pagination,
    PaginationProps,
    Popconfirm,
    Select,
    Space,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import InsPoolFrom from "./insPoolForm";
import insPoolApi from "../../../../apis/res/insPool";
import {TableDataType} from "../../../../apis/types/res/insPool";
import {OptionRes} from "../../../../apis/types/common";
import idcApi from "../../../../apis/res/idc";
import Details from "../../public/details/details";
import InsPoolTransferForm from "./insPoolTransferForm";
import insPool from "../../../../apis/res/insPool";


const InsPoolContent = () => {

    //进入页面先查询数据
    useEffect(() => {
        handleQuery();
        queryParams();
    }, []);

    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [model, setModel] = useState('')
    const [list, setList] = useState<TableDataType[]>([]);
    const [idList, setIdList] = useState<Number[]>([]);
    const [open, setOpen] = useState(false)
    const [openTransfer, setOpenTransfer] = useState(false)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [openSuccess, setOpenSuccess] = useState(false)

    // 下拉列表选项
    const [idcIdOption, setIdcIdOption] = useState<OptionRes[]>([])
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    // 表格
    const [total, setTotal] = useState<number>()
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);


    // 查询参数
    function queryParams() {
        idcApi.optinos().then((res) => {
            if (res) {

                setIdcIdOption(res.data)
            }
        })

    }

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        let res = insPoolApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setSelectedRowKeys([]);
                setIdList([]);
                setOpenSuccess(false)
            }
        })

    }

    //处理删除
    const handleDelete = () => {
        insPoolApi.batchDelete(idList).then((result) => {
            if (result) {
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });

    }

    const [messageApi, contextHolder] = message.useMessage();

    function cancelDerive() {
        messageApi.open({
            type: 'error',
            content: '取消',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }

    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {
            title: '操作',
            dataIndex: 'operation',
            render: (_: any, record: TableDataType) => {
                return <Typography.Link onClick={() => {
                    setOpen(true)
                    setModel('edit')
                    setRowData(record);
                    queryParams()
                }}>
                    编辑
                </Typography.Link>
            },
        },
        {
            title: '云手机池',
            dataIndex: 'poolName',
            key: 'poolName',
        },
        {
            title: '备注',
            dataIndex: 'notes',
            key: 'notes',
        },
        {
            title: '修改时间',
            dataIndex: 'modifyTime',
            key: 'modifyTime',
            ellipsis: true,
        },
        {
            title: '修改人',
            dataIndex: 'modifierDesc',
            key: 'modifierDesc',
        },

    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.id));
            //这里有个类型转换
            setIdList(Array.from(newSet) as Number[])
            setSelectedRowKeys(selectedRowKeys);
        }
    };

    //获取要查询的表单
    const [form] = Form.useForm();

    return (

        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>

                <Button type="primary" ghost onClick={() => {
                    setOpen(true)
                    setModel('create')
                    queryParams()
                }} style={{marginRight: '25px'}}>
                    新增
                </Button>

                <Button type={'primary'} ghost onClick={() =>{
                    setOpenTransfer(true)
                    setModel('transfer')
                    queryParams()
                }} style={{marginRight: '25px'}}>
                    转入
                </Button>
                <Popconfirm
                    title="是否确认删除所选项？"
                    onConfirm={() => {
                        if (idList.length === 0) {
                            message.error('删除失败，请选择需要删除的数据');
                        }else {
                            handleDelete();
                        }
                    }}
                    onCancel={() => {
                        message.error('取消删除');
                    }}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        删除
                    </Button>
                </Popconfirm>

            </div>

            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: '100vw', y: "70vh"}} rowSelection={{
                    ...rowSelection,"selectedRowKeys":selectedRowKeys,
                }} dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}/>

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination        size="small"style={{marginLeft: 'auto'}}
                                   total={total}
                                   showSizeChanger
                                   showQuickJumper
                                   current={currentPage}
                                   defaultPageSize={20}
                                   locale={paginationLocale}
                                   onChange={handlePaginationChange}
                                   showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>

            {/*新增和编辑的form表单，子组件*/}
            <InsPoolFrom
                refresh={() => {
                    //设置表单不展示
                    setOpen(false);
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
                record={rowData}
                open={open}
                closeOpen={() => {
                    setOpen(false)
                    setRowData({} as TableDataType)
                }}
                model={model}
                idcIdOption={idcIdOption}
            ></InsPoolFrom>

            <InsPoolTransferForm
                refresh={() => {
                    //设置表单不展示
                    setOpenTransfer(false);
                    setOpenSuccess(false)
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
                record={rowData}
                openTransfer={openTransfer}
                openSuccess={openSuccess}
                setOpenSuccess={setOpenSuccess}
                closeOpen={() => {
                    setOpenTransfer(false)
                    setOpenSuccess(false)
                    setRowData({} as TableDataType)
                }}
                model={model}
                idcIdOption={idcIdOption}
            >

            </InsPoolTransferForm>

            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>

        </div>
    )
}


export default InsPoolContent;