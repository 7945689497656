const baseUrl = "/mkt/redeemCode";
/** 获取产品申请列表-参数 */
export interface PageParam  {

}

//定义表格的属性
export interface TableDataType {
    id:number;
    batchNo:string;
    redeemCode:string;
    channelNote:string;
    poolName:string;
    redeemTypeDesc:string;
    productCodeDesc:string;
    redeemDuration:string;
    effTime:string;
    expTime:string;
    createTime:string;
    redeemStatusDesc:string;
    subscriberCode:string;
    instanceCode:string;
    redeemTime:string;
    statusCdDesc:string;
    notes:string;
    creatorDesc:string;
}

export  {baseUrl};

