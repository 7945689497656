import {baseUrl, PageParam, TableDataType} from "../types/res/insPool";
import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";
import commonApi from "../commonApi";

const insPoolApi = {
    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url+="current="+pagination.current+"&";
        url+="size="+pagination.pageSize+"&";
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(idList: Number[]) {
        let url = baseUrl + "/delete";
        return request.post(url, idList);
    },
    //创建新表单
    create(body: any) {
        let url = baseUrl;
        url += "/create";
        return request.post(url, body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + "/update";
        return request.put(url, body)
    },
    optinos(){
        let url=baseUrl;
        url+="/options"
        return request.get<OptionRes[]>(url)
    },
    transfer(body: any){
        let url = baseUrl + "/transfer";
        return request.post(url, body)
    }

}

export default insPoolApi



