import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";
import {baseUrl, PageParam, TableDataType} from "../types/pub/channel";
import commonApi from "../commonApi";

const channelApi = {

    pageData(body: PageParam,pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url+="current="+pagination.current+"&";
        url+="size="+pagination.pageSize+"&";
        if (body) {
            if (body.channelName) {
                url += 'channelName=' + encodeURIComponent(body.channelName)+"&";
            }
            if (body.channelCode) {
                url += 'channelCode=' + encodeURIComponent(body.channelCode)+"&";
            }
            if (body.channelTypeCd) {
                url += 'channelTypeCd=' + body.channelTypeCd+"&";
            }
            if (body.channelCategoryCd) {
                url += 'channelCategoryCd=' + body.channelCategoryCd+"&";
            }
            if (body.showHideApps) {
                url += 'showHideApps=' + body.showHideApps+"&";
            }
            if (body.showChannelName) {
                url += 'showChannelName=' + body.showChannelName+"&";
            }

        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(uidList: Number[]) {
        let url = baseUrl;
        if (uidList) {
            url += "?"
            const params = [...uidList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + id;
        return request.put(url, body)
    },
    //查询下拉列表数据
    optinos(){
        let url=baseUrl;
        url+="/options"
        return   request.get<OptionRes[]>(url)
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"渠道列表",body)
    },
}

export default channelApi;



