const baseUrl = "/pub/channel";
/** 获取产品申请列表-参数 */
export interface PageParam  {
    channelName:string,
    channelCode:string,
    channelTypeCd:string,
    channelCategoryCd:string,
    showHideApps: number,
    showChannelName: string,
}

//定义表格的属性
export interface TableDataType {
    id: number;
    channelName:string,
    channelCode:string,
    channelTypeDesc:string,
    channelCategoryDesc:string,
    channelContent: string;
    websiteUrl: string;
    showHideApps: number;
    statusDesc: string;
    notes: string;
}



export  {baseUrl};

