import React, {useEffect, useState} from "react";
import {Button, Form, Image, message, Modal, PaginationProps, Popconfirm, Table, Typography} from 'antd';
import {ColumnsType} from "antd/es/table";
import {TableDataType} from "../../../../../apis/types/pdt/customProduct";
import {OptionRes} from "../../../../../apis/types/common";
import propertiesApi from "../../../../../apis/sys/properties";
import Details from "../../../public/details/details";
import customProductApi from "../../../../../apis/pdt/customProduct";
import CustomProduct from "../../../../../apis/pdt/customProduct";
import ProductCustomFrom from "./productCustomForm";
import {imgIp} from "../../../../../components/constantConfig";
import instanceApi from "../../../../../apis/res/instance";


interface FormProps {
    productId: number;
}

const ProductCustomContent: React.FC<FormProps> = ({productId}) => {

    //进入页面先查询数据
    useEffect(() => {
        console.log("ProductAttributeContent productId: " + productId)
        handleQuery();
        queryParams();
    }, [productId]);

    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [model, setModel] = useState('')
    const [list, setList] = useState<TableDataType[]>([]);
    const [idList, setIdList] = useState<Number[]>([]);
    const [open, setOpen] = useState(false)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [openEnable, setOpenEnable] = useState(false);
    const [openDisable, setOpendisable] = useState(false);
    const [openCheckIds, setOpenCheckIds] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    // 表格
    const [total, setTotal] = useState<number>()
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 10000,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);


    // 查询参数
    function queryParams() {

    }

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        body.productId = productId;
        let res = customProductApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setSelectedRowKeys([]);

            }
        })

    }

    //处理删除
    const handleDelete = () => {
        customProductApi.batchDelete(idList).then((result) => {
            if (result) {
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });

    }


    //表格的内容
    const columns: ColumnsType<TableDataType> = [

        {
            title: '应用名称',
            dataIndex: 'appName',
            key: 'appName',
            ellipsis: true,
        },
        {
            title: '应用版本',
            dataIndex: 'appVersion',
            key: 'appVersion',
            ellipsis: true,
        },

        {
            title: '图标',
            dataIndex: 'iconPath',
            key: 'iconPath',
            render: (iconPath: string) => {
                return <Image src={iconPath} width={100}/>
            },
            ellipsis: true,
            width: 100
        },
        {
            title: '应用地址',
            dataIndex: 'filePath',
            key: 'filePath',
            ellipsis: true,
        },
        {
            title: '包名',
            dataIndex: 'packageName',
            key: 'packageName',
            ellipsis: true,
        },
        {
            title: '大小(M)',
            dataIndex: 'appMSize',
            key: 'appMSize',
            ellipsis: true,
        },
        {
            title: '状态',
            dataIndex: 'enableStatusDesc',
            key: 'enableStatusDesc',
            ellipsis: true,
        },
        {
            title: '备注',
            dataIndex: 'notes',
            key: 'notes',
            ellipsis: true,
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.id));
            //这里有个类型转换
            setIdList(Array.from(newSet) as Number[])
            setSelectedRowKeys(selectedRowKeys);

        }
    };

    //获取要查询的表单
    const [form] = Form.useForm();

    return (

        <div>

            {/*表格内容*/}
            <Table
                onRow={(record) => {
                    return {
                        onDoubleClick: (event) => {
                            console.log("details", record)
                            setDetailsOpen(true);
                            setRowData(record);
                        }
                    }
                }}
                size="small"  rowSelection={{
                ...rowSelection,"selectedRowKeys":selectedRowKeys,
            }} dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}/>

            {/*<Divider />*/}

            <div style={{textAlign: 'center', marginTop: '30px'}}>

                <Button type="primary" ghost onClick={() => {
                    setOpen(true)
                    setModel('create')
                }} style={{marginRight: '25px'}}>
                    新增应用
                </Button>

                <Button type="primary" ghost style={{marginRight: '25px'}} onClick={() => {
                    if (idList.length == 0) {
                        setOpenCheckIds(true)
                    } else {
                        setOpenEnable(true)
                    }
                }}>
                    启用
                </Button>

                <Button type="primary" danger style={{marginRight: '25px'}} onClick={() => {
                    if (idList.length == 0) {
                        setOpenCheckIds(true)
                    } else {
                        setOpendisable(true)
                    }
                }}>
                    禁用
                </Button>

                <Popconfirm
                    title="是否确认删除所选项？"
                    onConfirm={() => {
                        if (idList.length === 0) {
                            message.error('删除失败，请选择需要删除的数据');
                        } else {
                            handleDelete();
                        }
                    }}
                    onCancel={() => {
                        message.error('取消删除');
                    }}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        删除
                    </Button>
                </Popconfirm>

            </div>

            {/*新增和编辑的form表单，子组件*/}
            <ProductCustomFrom
                refresh={() => {
                    //设置表单不展示
                    setOpen(false);
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
                record={rowData}
                open={open}
                closeOpen={() => {
                    setOpen(false)
                    setRowData({} as TableDataType)
                }}
                model={model}
                productId={productId}
            ></ProductCustomFrom>

            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>
            <Modal
                cancelText="取消"
                okText="确认"
                title="" open={openEnable} onOk={() => {
                customProductApi.enable(idList).then((res) => {
                    if (res) {
                        setOpenEnable(false)
                        handleQuery();
                    }
                })            }} onCancel={() => {
                setOpenEnable(false)
            }}>
                该操作用于启用,请确认是否继续
            </Modal>
            <Modal
                cancelText="取消"
                okText="确认"
                title="" open={openDisable} onOk={() => {
                //禁用
                customProductApi.disable(idList).then((res) => {
                    if (res) {
                        setOpendisable(false)
                        handleQuery();
                    }
                })            }} onCancel={() => {
                setOpendisable(false)
            }}>
                该操作用于禁用,请确认是否继续
            </Modal>

            <Modal cancelText="取消"
                   okText="确认" title="" open={openCheckIds} onOk={() => {
                setOpenCheckIds(false)
            }} onCancel={() => {
                setOpenCheckIds(false)
            }}>
                先选择要操作的数据
            </Modal>
        </div>
    )
}


export default ProductCustomContent;