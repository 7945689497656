import React, {useEffect, useState} from "react";
import {
    Button,
    Col,
    ConfigProvider,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber, message,
    Modal,
    Pagination,
    PaginationProps, Popconfirm,
    Row,
    Select,
    Space,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import propertiesApi from "../../../../apis/sys/properties";
import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import OrderForm from "./orderForm";
import {TableDataType} from "../../../../apis/types/ord/order";
import orderApi from "../../../../apis/ord/order";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";
import Details from "../../public/details/details";
import channelParentVersionApi from "../../../../apis/pub/channel/channelParentVersion";


const {RangePicker} = DatePicker;
const OrderContent = () => {
    //进入页面先查询数据
    useEffect(() => {
        handleQuery();
        propertiesApi.optinos('DM_Order_Status').then((res) => {
            if (res) {
                setStatusOption(res.data)
            }
        })
        propertiesApi.optinos('DM_Order_Type').then((res) => {
            if (res) {

                setOrderTypeCdOption(res.data)
            }
        })
        propertiesApi.optinos('DM_Business_Type').then((res) => {
            if (res) {

                setBusinessTypeCdOption(res.data)
            }
        })
        propertiesApi.optinos('DM_Order_Source').then((res) => {
            if (res) {

                setOrderSourceOption(res.data)
            }
        })
        propertiesApi.optinos('DM_Owner_Type').then((res) => {
            if (res) {

                setOwnerTypeOption(res.data)
            }
        })
        propertiesApi.optinos('DM_Pay_Status').then((res) => {
            if (res) {

                setOwnerTypeOption(res.data)
            }
        })
    }, []);

    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [open, setOpen] = useState(false)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [statusOpen, setStatusOpen] = useState(false)
    const [refundOpen, setRefundOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [statusOption, setStatusOption] = useState<OptionRes[]>([])
    const [ownerTypeOption, setOwnerTypeOption] = useState<OptionRes[]>([])
    const [orderSourceOption, setOrderSourceOption] = useState<OptionRes[]>([])
    const [orderTypeCdOption, setOrderTypeCdOption] = useState<OptionRes[]>([])
    const [businessTypeCdOption, setBusinessTypeCdOption] = useState<OptionRes[]>([])
    const [total, setTotal] = useState<number>()
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    // 状态修改提交
    function updStatusOK() {
        let value = form1.getFieldsValue();
        orderApi.updStatus(value).then((result) => {
            if (result) {
                setStatusOpen(false);
                handleQuery();
            }
        })
    }

    // 状态修改取消
    function updStatusCancel() {
        form1.resetFields();
        setStatusOpen(false);
    }

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        body = {
            ...body,
            createTimeBegin: body.createTimeBegin ? dayjs(body.createTimeBegin).format('YYYY-MM-DD 00:00:00') : null,
            createTimeEnd: body.createTimeEnd ? dayjs(body.createTimeEnd).format('YYYY-MM-DD 00:00:00') : null,
            finishTimeBegin: body.finishTimeBegin ? dayjs(body.finishTimeBegin).format('YYYY-MM-DD 00:00:00') : null,
            finishTimeEnd: body.finishTimeEnd ? dayjs(body.finishTimeEnd).format('YYYY-MM-DD 00:00:00') : null,
        };
        console.log(body)
        let res = orderApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
            }
        })
    }

    const [messageApi, contextHolder] = message.useMessage();
    const handleAppDerive = () => {
        let body = form.getFieldsValue();
        body = {
            ...body,
            createTimeBegin: body.createTimeBegin ? dayjs(body.createTimeBegin).format('YYYY-MM-DD 00:00:00') : null,
            createTimeEnd: body.createTimeEnd ? dayjs(body.createTimeEnd).format('YYYY-MM-DD 00:00:00') : null,
            finishTimeBegin: body.finishTimeBegin ? dayjs(body.finishTimeBegin).format('YYYY-MM-DD 00:00:00') : null,
            finishTimeEnd: body.finishTimeEnd ? dayjs(body.finishTimeEnd).format('YYYY-MM-DD 00:00:00') : null,
        };
        orderApi.derive(body);
    }
    function confirmDerive() {
        handleAppDerive();
    }

    function cancelDerive() {
        messageApi.open({
            type: 'error',
            content: '取消',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }

    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {
            title: '操作',
            dataIndex: 'operation',
            render: (_: any, record: TableDataType) => (
                <div>
                    <Typography.Link onClick={() => {
                        setOpen(true)
                        setRowData(record);
                    }}>
                        详情
                    </Typography.Link>&nbsp;&nbsp;&nbsp;&nbsp;
                    <Typography.Link onClick={() => {
                        setStatusOpen(true)
                        form1.setFieldsValue({
                            id: record.id,
                            orderStatusCd: record.orderStatusCd
                        })
                    }}>
                        状态修改
                    </Typography.Link>
                </div>
            ),
            width: 115
        },
        {
            title: '订单编号',
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
            width: 130
        },
        {
            title: '订单类型',
            dataIndex: 'orderTypeCdDesc',
            key: 'orderTypeCdDesc',
            ellipsis: true,
            width: 80
        },
        {
            title: '会员名称',
            dataIndex: 'subscriberIdDesc',
            key: 'subscriberIdDesc',
            ellipsis: true,
            width: 80
        },
        {
            title: '会员ID',
            dataIndex: 'subscriberId',
            key: 'subscriberId',
            ellipsis: true,
            width: 80
        },
        {
            title: '会员账号',
            dataIndex: 'subUserIdDesc',
            key: 'subUserIdDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '发起渠道',
            dataIndex: 'channelIdDesc',
            key: 'channelIdDesc',
            ellipsis: true,
            width: 80
        },
        {
            title: '渠道编码',
            dataIndex: 'channelCode',
            key: 'channelCode',
            ellipsis:true,
            width:100
        },
        {
            title: '业务类型',
            dataIndex: 'businessTypeCdDesc',
            key: 'businessTypeCdDesc',
            ellipsis: true,
            width: 80
        },
        {
            title: '订单来源',
            dataIndex: 'orderSourceCdDesc',
            key: 'orderSourceCdDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '发起IP',
            dataIndex: 'sourceIp',
            key: 'sourceIp',
            ellipsis: true,
            width: 100
        },
        {
            title: '订阅数量',
            dataIndex: 'orderQuantity',
            key: 'orderQuantity',
            ellipsis: true,
            width: 80
        },
        {
            title: '订单金额',
            dataIndex: 'orderAmount',
            key: 'orderAmount',
            ellipsis: true,
            width: 80
        },
        {
            title: '活动商品',
            dataIndex: 'activityGoodsIdDesc',
            key: 'activityGoodsIdDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '优惠',
            dataIndex: 'discountIdDesc',
            key: 'discountIdDesc',
            ellipsis: true,
            width: 80
        },
        {
            title: '优惠金额',
            dataIndex: 'discountAmount',
            key: 'discountAmount',
            ellipsis: true,
            width: 80
        },
        {
            title: '支付方式',
            dataIndex: 'payMode',
            key: 'payMode',
            ellipsis: true,
            width: 80
        },
        {
            title: '支付金额',
            dataIndex: 'paymentAmount',
            key: 'paymentAmount',
            ellipsis: true,
            width: 80
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            ellipsis: true,
            width: 100
        },
        {
            title: '完成时间',
            dataIndex: 'finishTime',
            key: 'finishTime',
            ellipsis: true,
            width: 100
        },
        {
            title: '归属类型',
            dataIndex: 'ownerTypeCdDesc',
            key: 'ownerTypeCdDesc',
            ellipsis: true,
            width: 80
        },
        {
            title: '归属对象',
            dataIndex: 'ownerObjectIdDesc',
            key: 'ownerObjectIdDesc',
            ellipsis: true,
            width: 80
        },
        {
            title: '支付状态',
            dataIndex: 'payStatusCdDesc',
            key: 'payStatusCdDesc',
            ellipsis: true,
            width: 80
        },
        {
            title: '订单状态',
            dataIndex: 'orderStatusCdDesc',
            key: 'orderStatusCdDesc',
            ellipsis: true,
            width: 80
        },
        {
            title: '流水号',
            dataIndex: 'orderCode',
            key: 'orderCode',
            ellipsis: true,
            width: 200
        },
        {
            title: '商品',
            dataIndex: 'goodsIdDesc',
            key: 'goodsIdDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '支付时间',
            dataIndex: 'payTime',
            key: 'payTime',
            ellipsis: true,
            width: 100
        },
        {
            title: '分配时间',
            dataIndex: 'allocateTime',
            key: 'allocateTime',
            ellipsis: true,
            width: 100
        },
        {
            title: '备注',
            dataIndex: 'notes',
            key: 'notes',
            ellipsis: true,
            width: 100
        },
        {
            title: '支付序列号',
            dataIndex: 'paymentSerial',
            key: 'paymentSerial',
            ellipsis: true,
            width: 100
        },
        {
            title: '支付单流水号',
            dataIndex: 'paymentCode',
            key: 'paymentCode',
            ellipsis: true,
            width: 100
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.id));
            //这里有个类型转换
            // setIdList(Array.from(newSet) as Number[]);
        }
    };


    //获取要查询的表单
    const [form] = Form.useForm();
    const [form1] = Form.useForm();

    return (

        <div>

            {/*查询的表单*/}
            <Form form={form} name="validateOnly" layout="inline" autoComplete="off"
                  style={{maxWidth: 3000}}
                  size={"small"}
                  onFinish={() => {
                      pagination.current = 1;
                      setCurrentPage(1);
                      handleQuery();
                  }}
            >
                <Form.Item style={{margin: 5}} name="id" label="订单编号">
                    <Input style={{width: 150}} type="number"/>
                </Form.Item>
                <Form.Item style={{margin: 5}} name="paymentCode" label="支付单流水号">
                    <Input style={{width: 150}}/>
                </Form.Item>
                <Form.Item style={{margin: 5}} name="phoneNumber" label="电话号码">
                    <Input style={{width: 150}}/>
                </Form.Item>
                <Form.Item style={{margin: 5}} name="orderTypeCd" label="订单类型">
                    <Select
                        allowClear
                        style={{width: 150}}
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={orderTypeCdOption}
                    />
                </Form.Item>
                <Form.Item style={{margin: 5}} name="orderStatusCd" label="订单状态">
                    <Select
                        allowClear
                        style={{width: 150}}
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={statusOption}
                    />
                </Form.Item>
                <Form.Item style={{margin: 5}} name="businessTypeCd" label="业务类型">
                    <Select
                        allowClear
                        style={{width: 150}}
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={businessTypeCdOption}
                    />
                </Form.Item>
                <Form.Item name="createTimeBegin" label="订单创建时间开始" hidden={true}></Form.Item>
                <Form.Item name="createTimeEnd" label="订单创建时间结束" hidden={true}></Form.Item>
                <Form.Item style={{margin: 5}} name="createTime" label="订单创建时间">
                    <RangePicker
                        style={{width: 300}}
                        placeholder={['', '']}
                        onChange={(value) => {
                            if (value == null) {
                                form.setFieldValue("createTimeBegin", null);
                                form.setFieldValue("createTimeEnd", null);
                            } else {
                                if (Array.isArray(value)) {
                                    const valueArray = value;
                                    let date = Array.from(value);
                                    if (date.length == 2) {
                                        form.setFieldValue("createTimeBegin", date[0]);
                                        form.setFieldValue("createTimeEnd", date[1]);
                                    }
                                    console.log('onOk: ', date);
                                    console.log(form.getFieldsValue())
                                }
                            }
                        }}></RangePicker>
                </Form.Item>
                <Form.Item name="finishTimeBegin" label="订单完成时间开始" hidden={true}></Form.Item>
                <Form.Item name="finishTimeEnd" label="订单完成时间结束" hidden={true}></Form.Item>
                <Form.Item style={{margin: 5}} name="finishTime" label="订单完成时间">
                    <RangePicker
                        style={{width: 300}}
                        placeholder={['', '']}
                        onChange={(value) => {
                            if (value == null) {
                                form.setFieldValue("finishTimeBegin", null);
                                form.setFieldValue("finishTimeEnd", null);
                            } else {
                                if (Array.isArray(value)) {
                                    const valueArray = value;
                                    let date = Array.from(value);
                                    if (date.length == 2) {
                                        form.setFieldValue("finishTimeBegin", date[0]);
                                        form.setFieldValue("finishTimeEnd", date[1]);
                                    }
                                    console.log('onOk: ', date);
                                }
                            }
                        }}></RangePicker>
                </Form.Item>
                <div style={{display: 'flex', alignItems: 'center', padding: 0}}>
                <Form.Item >
                    <Space>
                        <Button type="primary" htmlType="submit">查询</Button>
                        <Button htmlType="reset" onClick={() => {
                            const body = {};
                            orderApi.pageData(body, pagination).then((res) => {
                                if (res) {
                                    setList(res.data.records)
                                    setTotal(res.data.total)
                                }
                            })
                        }}>重置</Button>
                        <Popconfirm
                            title="是否确认导出筛选的数据？"
                            onConfirm={confirmDerive}
                            onCancel={cancelDerive}
                            okText="是"
                            cancelText="否"
                        >
                            <Button type="primary" danger style={{marginRight: '25px'}}>
                                导出
                            </Button>
                        </Popconfirm>
                    </Space>
                </Form.Item>
                </div>
            </Form>

            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: '100vw', y: "68vh"}} dataSource={list}
                    columns={columns} rowKey={record => record.id} pagination={false}/>

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination size="small" style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>


            {/*新增和编辑的form表单，子组件*/}
            <OrderForm

                record={rowData}
                open={open}
                closeOpen={() => {
                    setOpen(false)
                    setRowData({} as TableDataType)
                }}
                refresh={() => {
                    //设置表单不展示
                    setOpen(false);
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
            ></OrderForm>

            {/*状态变更对话框*/}
            <Modal title="订单状态修改" open={statusOpen} okText={"提交"} cancelText={"取消"} onOk={updStatusOK}
                   onCancel={updStatusCancel}>
                <Form form={form1} name="validateOnly" layout="inline" autoComplete="off"
                      onFinish={() => {
                          pagination.current = 1;
                          setCurrentPage(1);
                          handleQuery();
                      }}
                >
                    <Form.Item name="id" label="订单编号" hidden={true}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name="orderStatusCd" label="订单状态">
                        <Select
                            allowClear
                            style={{width: 300}}
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={statusOption}
                        />
                    </Form.Item>
                    <Form.Item name="notes" label="备注" style={{marginLeft: 30, marginTop: 20}}>
                        <TextArea rows={4} style={{width: 300}}/>
                    </Form.Item>
                </Form>
            </Modal>


            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>

        </div>
    )
}


export default OrderContent;