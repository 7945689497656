import React from 'react';
import { Tabs } from 'antd';
import RedeemCode from "./RedeemCode";
import RedeemCodeUse from "./redeemUse/RedeemCodeUse";
const RedeemCodeContent = () => (
    <Tabs
        defaultActiveKey="1"
        items={[
            {
                key: '1',
                label: '生成日志',
                children: <RedeemCode />,
            },
            {
                key: '2',
                label: '兑换日志',
                children: <RedeemCodeUse />,
            },
        ]}
    />
);

export default RedeemCodeContent;