import React, {useEffect} from 'react';
import {Form, Input, Modal, Select} from 'antd';

import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import {TableDataType} from "../../../../apis/types/pub/channel";
import channelApi from "../../../../apis/pub/channel";
import TextArea from "antd/es/input/TextArea";


const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface ChannelFormProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    model: String;
    statusOption: OptionRes[];
    channelTypeCdOption: OptionRes[];
    channelCategoryCdOption: OptionRes[];
    channelMarketShowOption: OptionRes[];
}

const ChannelForm: React.FC<ChannelFormProps> = ({
                                                     refresh,
                                                     record,
                                                     open,
                                                     closeOpen,
                                                     model,
                                                     statusOption,
                                                     channelTypeCdOption,
                                                     channelCategoryCdOption,
                                                     channelMarketShowOption
                                                 }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        // 在组件挂载时，设置表单数据为record的值
        form.setFieldsValue(
            {
                ...record,
                // 将数字格式转成字符串格式 （注意：js中值为0时， record.showHideApps 会被判断为false。所以要直接判断不为空和不为undefined）
                showHideApps: record.showHideApps !== null && record.showHideApps !== undefined
                    ? record.showHideApps.toString() : "1",
            });
    }, [open]);

    //处理新增方法
    const handleCreate = () => {
        let values = form.getFieldsValue();
        channelApi.create(values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields()
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        let values = form.getFieldsValue();
        let valueJSOn = JSON.stringify(values);
        channelApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });

    }

    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }


    return (
        <Modal
            title={model == 'create' ? "新增项目" : "编辑项目"}
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}

        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                style={{maxWidth: 500}}
            >

                <Form.Item name="id" label="渠道编号" hidden={true}>
                </Form.Item>
                <Form.Item name="channelName" label="渠道名称（对外展示）" rules={[{required: true, message: "渠道编码必填"},{max:128,message:"渠道名称（分包用）长度不能超过128位"}]}>
                    <Input/>
                </Form.Item>
                <Form.Item name="showChannelName" label="渠道名称（后台展示）" rules={[{max:128,message:"渠道名称（后台展示）长度不能超过128位"}]}>
                    <Input/>
                </Form.Item>
                <Form.Item name="channelCode" label="渠道编码" rules={[{required: true, message: "渠道编码必填"},{max:32,message:"渠道编码长度不能超过32位"}]}>
                    <Input/>
                </Form.Item>
                <Form.Item name="channelTypeCd" label="渠道类型" rules={[{required: true, message: "渠道类型必填"}]}>
                    <Select
                        allowClear
                        style={{width: 200}}
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={channelTypeCdOption}
                    />
                </Form.Item>
                <Form.Item name="channelCategoryCd" label="渠道分类"
                           rules={[{required: true, message: "渠道分类必填"}]}>
                    <Select
                        allowClear
                        style={{width: 200}}
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={channelCategoryCdOption}
                    />
                </Form.Item>
                <Form.Item name="channelContent" label="渠道介绍" rules={[{max:255,message:"渠道介绍长度不能超过255位"}]}>
                    <Input/>
                </Form.Item>
                <Form.Item name="websiteUrl" label="渠道官网" rules={[{max:255, message: "渠道官网长度不能超过255位"}]}>
                    <Input/>
                </Form.Item>
                <Form.Item name="showHideApps" label="显示/隐藏应用" rules={[{required: true, message: "必填"}]}>
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={channelMarketShowOption}
                    />
                </Form.Item>
                <Form.Item name="statusCd" label="状态" rules={[{required: true, message: "状态必填"}]}>
                    <Select
                        style={{width: 200}}
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={statusOption}
                    />
                </Form.Item>
                <Form.Item name="notes" label="备注" rules={[{max:255, message: "备注长度不能超过255位"}]}>
                    <TextArea rows={1}/>
                </Form.Item>
            </Form>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default ChannelForm;



