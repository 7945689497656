import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";
import {PageParam, TableDataType, baseUrl} from "../types/sub/subscriber";
import commonApi from "../commonApi";

const subscriberApi = {
    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url+="current="+pagination.current+"&";
        url+="size="+pagination.pageSize+"&";
        if (body) {
            if (body.id) {
                url += '&id=' + encodeURIComponent(body.id);
            }
            if (body.subscriberCode) {
                url += '&subscriberCode=' + encodeURIComponent(body.subscriberCode);
            }
            if (body.acctNo) {
                url += '&acctNo=' + encodeURIComponent(body.acctNo);
            }
            if (body.nickName) {
                url += '&nickName=' + encodeURIComponent(body.nickName);
            }
            if (body.channelName) {
                url += '&channelName=' + encodeURIComponent(body.channelName);
            }
            if (body.showChannelName) {
                url += '&showChannelName=' + encodeURIComponent(body.showChannelName);
            }
            if (body.channelCode) {
                url += '&channelCode=' + encodeURIComponent(body.channelCode);
            }
            if (body.phoneNumber) {
                url += '&phoneNumber=' + encodeURIComponent(body.phoneNumber);
            }
            if (body.beginRegistTime) {
                url += '&beginRegistTime=' + body.beginRegistTime;
            }
            if (body.endRegistTime) {
                url += '&endRegistTime=' + body.endRegistTime;
            }
            if(body.notes){
                url += '&notes=' + encodeURIComponent(body.notes);
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(idList: Number[]) {
        let url = baseUrl;
        if (idList) {
            url += "?"
            const params = [...idList].map((id) => `ids=${id}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + id;
        return request.put(url, body)
    },
    optinos(){
        let url=baseUrl;
        url+="/options"
        return   request.get<OptionRes[]>(url)
    },
    // 查询方法
    listActionMethods() {
        const data = request.get(baseUrl + "/enums/ActionMethod").then((result) => {
            return result;
        })
        return data;
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"会员列表",body)
    },

}

export default subscriberApi




