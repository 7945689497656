import React, {useEffect, useState} from 'react';
import {
    Button,
    Col,
    Divider,
    Form,
    message,
    Modal,
    PaginationProps,
    Popconfirm,
    Row,
    Select
} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {filterOption} from "../../../../apis/common";
import {OptionRes} from "../../../../apis/types/common";
import TextArea from "antd/es/input/TextArea";
import {TableDataType} from "../../../../apis/types/res/insPool";
import insPoolApi from "../../../../apis/res/insPool";

// moment，并设置本地化语言为中文
moment.locale('zh-cn');
const {Option} = Select;


interface FromProps {
    refresh: () => void;
    closeOpen: () => void;
    setOpenSuccess: (value: boolean) => void;
    openSuccess: boolean;
    record: TableDataType;
    openTransfer: boolean;
    model: String;
    // option
    idcIdOption: OptionRes[];
}


const InsPoolTransferForm: React.FC<FromProps> = ({
                                              refresh, record, openTransfer, closeOpen, openSuccess, setOpenSuccess,
                                              idcIdOption,
                                          }) => {
    const [form] = Form.useForm();
    const [insPoolNameOption, setInsPoolNameOption] = useState<OptionRes[]>([])
    const [insPoolName, setInsPoolName] = useState('')

    const [successCount, setSuccessCount] = useState(0);
    const [failCount, setFailCount] = useState(0);
    const [failInstanceIpList, setFailInstanceIpList] = useState<string[]>([]);
    const [isRefresh, setIsRefresh] = useState(false);
    const [copied, setCopied] = useState(false);
    //处理新增方法
    const handleCreate = () => {
        insPoolApi.transfer(form.getFieldsValue()).then((result) => {
            if (result) {
                setOpenSuccess(true)
                setSuccessCount(result.data.successCount)
                setFailCount(result.data.failCount)
                setFailInstanceIpList(result.data.failInstanceIpList)
            }
        });
    };

    const handleSuccess= () => {
        form.resetFields()
        setOpenSuccess(false)
        setIsRefresh(true)
    }

    useEffect(() => {
        refresh()
        setIsRefresh(false)
    }, [isRefresh]);


    const handleCopy = async () => {
        if (navigator.clipboard && window.isSecureContext) {
            try {
                await navigator.clipboard.writeText(failInstanceIpList.join('\n'));
                message.success('复制成功');
                setCopied(true);
                setTimeout(() => setCopied(false), 2000); // 2秒后自动清除复制状态
            } catch (err) {
                console.error('Failed to copy text: ', err);
                message.error('复制失败');
            }
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = failInstanceIpList.join('\n');
            textArea.style.position = 'fixed';
            textArea.style.opacity = '0';
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                document.execCommand('copy') ? message.success('复制成功') : message.error('复制失败');
                setCopied(true);
                setTimeout(() => setCopied(false), 2000); // 2秒后自动清除复制状态
            } catch (err) {
                console.error('Failed to copy text: ', err);
                message.error('复制失败');
            }
            document.body.removeChild(textArea);
        }
    };


    //进入页面查询数据
    useEffect(() => {
        queryParams();
    }, [openTransfer]);

    // 查询参数
    function queryParams() {
        insPoolApi.optinos().then((res) => {
            if (res) {
                setInsPoolNameOption(res.data)
            }
        })

    }

    const ipSegmentPattern = /^[0-9.-]+(?:\r?\n[0-9.-]+)*$/

    //处理取消方法
    const handleCancel = () => {
        setOpenSuccess(false);
        form.resetFields();
        closeOpen()
    };


    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            console.log(form.getFieldsValue())
            handleCreate();
        })
            .catch((error) => {
                console.log(error)
            });
    }

    return (
        <Modal
            title={"转入云手机"}
            open={openTransfer}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}
            footer={''}
        >
            <Form
                layout={"vertical"}
                form={form}
                name="control-hooks"
                // onFinish={onFinish}
                style={{maxWidth: 400, height: 500}}
            >
                <Form.Item name="instanceIps" label={"云手机IP/IP段:"} layout={"vertical"} rules={[{required: true, message: "云手机IP/IP段必填"},
                    {pattern: ipSegmentPattern, message: '请输入有效的IP段'}
                ]} >
                    <TextArea rows={18} style={{width: 400, resize: 'none'}} placeholder={"IP段以英文横杠连接 ，换行输入"}/>
                </Form.Item>

                <Form.Item name="insPoolId" label={"转入云手机池:"} rules={[{required: true, message: "目标池必填"}]}>
                    <Select
                        style={{width: 175}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={insPoolNameOption}
                        onChange={(value, option) => setInsPoolName(option?.label)}
                    >

                    </Select>
                </Form.Item>

            </Form>
            <Divider/>
            <Row>
                <Col span={12}>
                    <Button
                        name={"cancel"} onClick={handleCancel} style={{marginRight: '25px'}}
                    >取消</Button>
                </Col>
                <Col span={12}>
                    <Popconfirm title={"确认转入至云手机池 :"+ " " + insPoolName}
                                onConfirm={handleOK}
                                onCancel={() =>{
                                    message.error('取消提交');
                                    }
                                }
                                okText={"是"}
                                cancelText={"否"}
                    >
                        <Button
                            name={"submit"} type="primary" style={{marginRight: '25px'}}
                        >提交</Button>
                    </Popconfirm>
                </Col>
            </Row>
            <Modal
                okText="确认"
                cancelText="关闭弹窗"
                title="转让结果"
                open={openSuccess}
                onOk={() => {
                    setOpenSuccess(false);
                    handleSuccess();
                }}
                onCancel={() => {
                    setOpenSuccess(false);
                }}
            >
                <Form
                    name="control-hooks"
                    style={{maxWidth: 300}}
                >
                    {/* 显示成功和失败的数量 */}
                    <Form.Item label="操作结果">
                        <div>
                            转入成功：{successCount}
                            <Divider type="vertical"/>
                            转入失败：{failCount}
                        </div>
                    </Form.Item>
                    <Row>
                        <Col span={18}>
                            <Form.Item label="失败列表">
                                <div>
                                    {failInstanceIpList.map((item, index) => (
                                        <div key={index}>{item}</div>
                                    ))}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            {failInstanceIpList.length > 0 && (
                            <Button type="primary" onClick={handleCopy} disabled={copied}>
                                {copied ? '已复制' : '一键复制'}
                            </Button>
                            )}
                        </Col>
                    </Row>

                </Form>
            </Modal>


        </Modal>


    );
};

/**
 * 查询的表单
 * @constructor
 */


export default InsPoolTransferForm;



