import {baseUrl, PageParam, TableDataType} from "../types/pdt/customProduct";
import {request} from "../../utils";
import {PageRes} from "../types/common";
import {PaginationProps} from "antd";
import {domain} from "../../components/constantConfig";

const customProductApi = {
    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl+"/page";
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {

            if (body.productId) {
                url += '&productId=' + body.productId;
            }
        }
        return request.post<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(idList: Number[]) {
        let url = baseUrl+"/delete";
        if (idList) {
            url += "?"
            const params = [...idList].map((id) => `ids=${id}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl + "/add", body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/update/' + id;
        return request.put(url, body)
    },
    //启用
    enable(idList: Number[]) {
        let url = baseUrl + "/enable";
        if (idList) {
            url += "?"
            const params = [...idList].map((id) => `ids=${id}`).join('&');
            url += params;
        }
        return request.get(url);
    },
    //禁用
    disable(idList: Number[]) {
        let url = baseUrl + "/disable";
        if (idList) {
            url += "?"
            const params = [...idList].map((id) => `ids=${id}`).join('&');
            url += params;
        }
        return request.get(url);
    },
    //上传
    getUploadUrl(){
        return domain + baseUrl + "/uploadFile";
    },

}

export default customProductApi



