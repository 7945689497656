import React, {useEffect, useState} from "react";
import {
    Button, ConfigProvider,
    Divider,
    Form,
    Input, message,
    Pagination,
    PaginationProps, Popconfirm,
    Select,
    Space,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import ProductForm from "./productForm";
import productApi from "../../../../apis/pdt/product";
import {TableDataType} from "../../../../apis/types/pdt/product";
import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import propertiesApi from "../../../../apis/sys/properties";
import Details from "../../public/details/details";
import channelParentVersionApi from "../../../../apis/pub/channel/channelParentVersion";


const ProductContent = () => {

    //进入页面先查询数据
    useEffect(() => {
        handleQuery();
        queryParams();
    }, []);

    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [model, setModel] = useState('')
    const [list, setList] = useState<TableDataType[]>([]);
    const [open, setOpen] = useState(false)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)

    // 下拉列表选项
    const [productTypeCdOption, setProductTypeCdOption] = useState<OptionRes[]>([])
    const [displayModeCdOption, setDisplayModeCdOption] = useState<OptionRes[]>([])
    const [ownerTypeCdOption, setOwnerTypeCdOption] = useState<OptionRes[]>([])
    const [statusCdOption, setStatusCdOption] = useState<OptionRes[]>([])
    // 表格
    const [total, setTotal] = useState<number>()
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);


    // 查询参数
    function queryParams() {
        propertiesApi.optinos('DM_Product_Type').then((res) => {
            if (res) {

                setProductTypeCdOption(res.data)
            }
        })
        propertiesApi.optinos('DM_Display_Mode').then((res) => {
            if (res) {

                setDisplayModeCdOption(res.data)
            }
        })
        propertiesApi.optinos('DM_Owner_Type').then((res) => {
            if (res) {

                setOwnerTypeCdOption(res.data)
            }
        })
        propertiesApi.optinos('DM_Product_Status').then((res) => {
            if (res) {

                setStatusCdOption(res.data)
            }
        })

    }

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        let res = productApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
            }
        })

    }

    const [messageApi, contextHolder] = message.useMessage();
    const handleAppDerive = () => {
        let body = form.getFieldsValue();
        productApi.derive(body);
    }
    function confirmDerive() {
        handleAppDerive();
    }

    function cancelDerive() {
        messageApi.open({
            type: 'error',
            content: '取消',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }

    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {
            title: '操作',
            dataIndex: 'operation',
            render: (_: any, record: TableDataType) => {
                return <Typography.Link onClick={() => {
                    setOpen(true)
                    setModel('edit')
                    setRowData(record);
                    queryParams()
                }}>
                    编辑
                </Typography.Link>
            },
            ellipsis: true,
            width: 80
        },
        {
            title: '产品名称',
            dataIndex: 'productName',
            key: 'productName',
            ellipsis: true,
            width: 150
        },
        {
            title: '产品编码',
            dataIndex: 'productCode',
            key: 'productCode',
            ellipsis: true,
            width: 150
        },
        {
            title: '产品类型',
            dataIndex: 'productTypeDesc',
            key: 'productTypeDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '个性化类型',
            dataIndex: 'customProductDesc',
            key: 'customProductDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '标准价',
            dataIndex: 'unitPrice',
            key: 'unitPrice',
            ellipsis: true,
            width: 100
        },
        {
            title: '关联的对象',
            dataIndex: 'relaObject',
            key: 'relaObject',
            ellipsis: true,
            width: 100
        },
        {
            title: '展示方式',
            dataIndex: 'displayModeDesc',
            key: 'displayModeDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '归属类型',
            dataIndex: 'ownerTypeDesc',
            key: 'ownerTypeDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '归属对象',
            dataIndex: 'ownerObjectId',
            key: 'ownerObjectId',
            ellipsis: true,
            width: 100
        },
        {
            title: '默认选中',
            dataIndex: 'defaultSelectedDesc',
            key: 'defaultSelectedDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '状态',
            dataIndex: 'statusDesc',
            key: 'statusDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '排序',
            dataIndex: 'reorder',
            key: 'reorder',
            ellipsis: true,
        },
        {
            title: '备注',
            dataIndex: 'notes',
            key: 'notes',
            ellipsis: true,
            width: 300
        },
    ];

    //获取要查询的表单
    const [form] = Form.useForm();

    return (

        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>

                <Button type="primary" ghost onClick={() => {
                    setOpen(true)
                    setModel('create')
                    queryParams()
                }} style={{marginRight: '25px'}}>
                    新增
                </Button>
                {/*<Popconfirm*/}
                {/*    title="是否确认删除所选项？"*/}
                {/*    onConfirm={() => {*/}
                {/*        if (idList.length === 0) {*/}
                {/*            message.error('删除失败，请选择需要删除的数据');*/}
                {/*        }else {*/}
                {/*            handleDelete();*/}
                {/*        }*/}
                {/*    }}*/}
                {/*    onCancel={() => {*/}
                {/*        message.error('取消删除');*/}
                {/*    }}*/}
                {/*    okText="是"*/}
                {/*    cancelText="否"*/}
                {/*>*/}
                {/*    <Button type="primary" danger style={{marginRight: '25px'}}>*/}
                {/*        删除*/}
                {/*    </Button>*/}
                {/*</Popconfirm>*/}
                <Popconfirm
                    title="是否确认导出筛选的数据？"
                    onConfirm={confirmDerive}
                    onCancel={cancelDerive}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        导出
                    </Button>
                </Popconfirm>


                {/*查询的表单*/}
                <Form form={form} name="validateOnly" layout="inline" autoComplete="off"
                      style={{marginLeft: 'auto'}}
                      onFinish={() => {
                          pagination.current = 1;
                          setCurrentPage(1);
                          handleQuery();
                      }}
                >
                    <Form.Item name="productName" label="产品名称">
                        <Input/>
                    </Form.Item>

                    <Form.Item name="productTypeCd" label="产品类型">
                        <Select
                            style={{width: 150}}
                            showSearch

                            optionFilterProp="children"
                            allowClear

                            filterOption={filterOption}
                            options={productTypeCdOption}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">查询</Button>
                            <Button htmlType="reset" onClick={() => {
                                const body = {};
                                productApi.pageData(body, pagination).then((res) => {
                                    if (res) {
                                        setList(res.data.records)
                                        setTotal(res.data.total)
                                    }
                                })
                            }}>重置</Button> </Space>
                    </Form.Item>
                </Form>
            </div>


            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: 'max-content', y: "70vh"}} dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}/>

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination size="small" style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>

            {/*新增和编辑的form表单，子组件*/}
            <ProductForm
                refresh={() => {
                    //设置表单不展示
                    setOpen(model === 'edit');
                    // setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
                record={rowData}
                open={open}
                closeOpen={() => {
                    setOpen(false)
                    setRowData({} as TableDataType)
                }}
                model={model}
                productTypeCdOption={productTypeCdOption}
                displayModeCdOption={displayModeCdOption}
                ownerTypeCdOption={ownerTypeCdOption}
                statusCdOption={statusCdOption}
            ></ProductForm>

            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>

        </div>
    )
}


export default ProductContent;