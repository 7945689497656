import React, {useEffect, useState} from "react";
import {
    Button,
    ConfigProvider,
    DatePicker,
    Divider,
    Form,
    Input,
    message,
    Pagination,
    PaginationProps,
    Popconfirm,
    Select,
    Space,
    Table
} from 'antd';
import {ColumnsType} from "antd/es/table";

import {OptionRes} from "../../../../../apis/types/common";
import {filterOption} from "../../../../../apis/common";
import {TableDataType} from "../../../../../apis/types/mkt/redeemCode/redeemCodeUseVo";
import dayjs from "dayjs";
import Details from "../../../mkt/redeemCode/details";
import propertiesApi from "../../../../../apis/sys/properties";
import redeemCodeUseApi from "../../../../../apis/mkt/redeemCode/redeemCodeUseApi";

const {RangePicker} = DatePicker;

const RedeemCodeUse = () => {
    //进入页面先查询数据
    useEffect(() => {
        handleQuery();
        propertiesApi.optinos("DM_RedeemCode_Use_Result").then((res) => {
            if (res) {
                setStatusOption(res.data)
            }
        })
        propertiesApi.optinos("DM_Redeem_Type").then((res) => {
            if (res) {
                setRedeemTypeOption(res.data)
            }
        })

    }, []);

    //定义用到的useState，用来绑定数据
    const [model, setModel] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [idList, setIdList] = useState<Number[]>([]);
    const [open, setOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [statusOption, setStatusOption] = useState<OptionRes[]>([])
    const [redeemTypeOption, setRedeemTypeOption] = useState<OptionRes[]>([])
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const [total, setTotal] = useState<number>()
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();

        var beginRedeemTime = form.getFieldValue('beginRedeemTime');
        var endRedeemTime = form.getFieldValue('endRedeemTime');

        body = {
            ...body,
            beginRedeemTime: beginRedeemTime ? dayjs(beginRedeemTime).format('YYYY-MM-DD 00:00:00') : null,
            endRedeemTime: endRedeemTime ? dayjs(endRedeemTime).format('YYYY-MM-DD 00:00:00') : null,
        };

        let res = redeemCodeUseApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setSelectedRowKeys([]);
                setIdList([]);
            }
        })

    }

    const [messageApi, contextHolder] = message.useMessage();
    const handleAppDerive = () => {
        let body = form.getFieldsValue();
        var beginRedeemTime = form.getFieldValue('beginRedeemTime');
        var endRedeemTime = form.getFieldValue('endRedeemTime');

        body = {
            ...body,
            beginRedeemTime: beginRedeemTime ? dayjs(beginRedeemTime).format('YYYY-MM-DD 00:00:00') : null,
            endRedeemTime: endRedeemTime ? dayjs(endRedeemTime).format('YYYY-MM-DD 00:00:00') : null,
        };
        redeemCodeUseApi.derive(body);
    }


    function confirmDerive() {
        handleAppDerive();
    }

    function cancelDerive() {
        messageApi.open({
            type: 'error',
            content: '取消',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }




    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {title: "兑换时间", dataIndex: "redeemTime", key: "redeemTime", ellipsis: true, width: 100},
        {title: "会员编码", dataIndex: "subscriberCode", key: "subscriberCode", ellipsis: true, width: 100},
        {title: "兑换码", dataIndex: "redeemCode", key: "redeemCode", ellipsis: true, width: 100},
        {title: "兑换类型", dataIndex: "redeemTypeDesc", key: "redeemTypeDesc", ellipsis: true, width: 100,},
        {title: "产品等级", dataIndex: "productName", key: "productName", ellipsis: true, width: 100,},
        {title: "兑换时长(小时)", dataIndex: "redeemDuration", key: "redeemDuration", ellipsis: true, width: 100},
        {title: "状态", dataIndex: "redeemStatusDesc", key: "redeemStatusDesc", ellipsis: true, width: 100},
    ];


    //获取要查询的表单
    const [form] = Form.useForm();

    return (

        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>

                {/*查询的表单*/}
                <Form form={form} name="validateOnly" layout="inline" autoComplete="off"
                      style={{marginLeft: 'auto'}}
                      onFinish={() => {
                          pagination.current = 1;
                          setCurrentPage(1);
                          handleQuery();
                      }}
                >

                    <Form.Item style={{margin: 5}} name="subscriberCode" label="会员编码">
                        <Input size="small"/>
                    </Form.Item>

                    <Form.Item style={{margin: 5}} name="redeemCode" label="兑换码">
                        <Input size="small"/>
                    </Form.Item>


                    <Form.Item style={{ margin: 5 }} name="redeemType" label="兑换类型">
                        <Select
                            style={{ width: 150 }}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={redeemTypeOption.filter(option => option.value !== 'S03')}
                            size="small"
                        />
                    </Form.Item>

                    <Form.Item style={{margin: 5}} name="redeemStatus" label="状态">
                        <Select
                            style={{width: 150}}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={statusOption}
                            size="small"
                        />
                    </Form.Item>

                    {/* 这里的codeCreateTime参数实际上服务端接口是不需要的，是为了重置的时候把页面上的日期选择器显示重置掉   */}
                    <Form.Item style={{margin: 5}} label="兑换时间" name={['redeemTime', 'dates']}>
                        <RangePicker
                            style={{width: 250}}
                            placeholder={['', '']}
                            onChange={(value) => {
                                if (value == null) {
                                    form.setFieldValue("beginRedeemTime", null);
                                    form.setFieldValue("endRedeemTime", null);
                                } else if (Array.isArray(value)) {
                                    let date = Array.from(value);
                                    if (date.length == 2) {
                                        form.setFieldValue("beginRedeemTime", date[0]);
                                        form.setFieldValue("endRedeemTime", date[1]);
                                    }
                                    console.log('onOk: ', date);
                                }
                            }}
                            size="small"
                        />
                    </Form.Item>


                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit" size="small" style={{margin: 8}}>查询</Button>
                            <Button htmlType="reset" onClick={() => {
                                form.resetFields()
                                handleQuery()
                            }} size="small" style={{margin: 8}}>重置</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </div>

            <Divider style={{height: 1, marginTop: 10, marginBottom: 5}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>




                <Button type="primary" onClick={confirmDerive} style={{marginRight: '25px'}} size="small">
                    导出
                </Button>



                <Divider style={{height: 1, marginTop: 10, marginBottom: 5}}/>



                {/*表格内容*/}
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: '100vw', y: "60vh"}}  dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}/>

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination size="small" style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>



            {/*鼠标双击出现的详情展示表单*/}
            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>

        </div>
    )
}


export default RedeemCodeUse;