import React, {useEffect, useState} from 'react';
import {Divider, Form, Input, InputNumber, Modal, Select} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import insPoolApi from "../../../../apis/res/insPool";
import {OptionRes} from "../../../../apis/types/common";
import TextArea from "antd/es/input/TextArea";
import {TableDataType} from "../../../../apis/types/res/insPool";

// moment，并设置本地化语言为中文
moment.locale('zh-cn');
const {Option} = Select;


const layout = {
    labelCol: {span: 10},
    wrapperCol: {span: 16},
};


interface FromProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    model: String;
    // option
    idcIdOption: OptionRes[];
}


const InsPoolForm: React.FC<FromProps> = ({
                                                  refresh, record, open, closeOpen, model,
                                                  idcIdOption,
                                              }) => {
    const [form] = Form.useForm();
    const [isEditDisabled, setIsEditDisabled] = useState(false);

    useEffect(() => {
        if (open && record) {
            form.setFieldsValue({ ...record });
            setIsEditDisabled(record.id == 0); // 根据record的id设置isEditDisabled状态
        }
    }, [open, record]);

    //处理新增方法
    const handleCreate = () => {
        insPoolApi.create(form.getFieldsValue()).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields();
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        const values = form.getFieldsValue();
        let valueJSOn = JSON.stringify(values);
        insPoolApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {

                handleCancel();
                refresh();

            }
        });

    }


    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            console.log(form.getFieldsValue())
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {
                console.log(error)
            });
    }

    return (
        <Modal
            title={model == 'create' ? "新增云手机池" : "编辑云手机池"}
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}
            okButtonProps={{ disabled: isEditDisabled }}
        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                // onFinish={onFinish}
                style={{maxWidth: 300}}
            >

                <Form.Item name="id" label="云手机池编号" hidden={true}>
                </Form.Item>
                <Form.Item name="poolName" label="云手机池名称" rules={[{required: true, message: "云手机池名称必填"},
                    {
                    pattern: /^[\u4e00-\u9fa5a-zA-Z0-9]+$/,
                    message: "云手机池名称只能包含中文、英文大小写及数字字符"
                }]}>
                        <Input maxLength={100}/>
                </Form.Item>
                <Form.Item name="notes" label="备注" rules={[{required: true, message: "备注必填"}]}>
                    <TextArea rows={4} maxLength={50}/>
                </Form.Item>

            </Form>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default InsPoolForm;



