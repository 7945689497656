const baseUrl = "/res/insPool";
/** 获取列表-参数 */
export interface PageParam {

}

//定义表格的属性
export interface TableDataType {
    id: number;

}

export  {baseUrl};